<template>
  <div class="checking-final-contract">
    <img class="checking-final-contract__success-icon" src="../../assets/icons/icon-success.svg" alt="success icon">
    <div class="checking-final-contract__title">
      Спасибо, происходит проверка договора!
    </div>
    <ButtonElement 
      class="checking-final-contract__btn"
      text="Ок"
      @click-button="closeDialog()"
    />
  </div>
</template>

<script>
import ButtonElement from '../../components/elements/ButtonElement'

export default {
  name: 'CheckingFinalContract',
  components: {
    ButtonElement,
  },
  methods: {
    closeDialog() {
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.checking-final-contract {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10rem;
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.5rem;
    color: $basicBlack;
    margin-bottom: 1.5rem;
  }
  &__success-icon {
    margin-bottom: 2rem;
  }

  &__btn {
    margin-top: 3.2rem;
    padding: 2.7rem 6.4rem;
  }
}

</style>
